@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        ecocharity
Version:        2.0
Last change:    02.20.2016
Primary use:    Ecocharity - Nonprofit Environment HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-ecocharity/table-of-content.less";

 
// Initialize Variables
@import "less-ecocharity/variables.less";
@import "less-ecocharity/mixins.less";
// Typography
@import "less-ecocharity/typography.less";

// Common Styles
@import "less-ecocharity/common.less";
@import "less-ecocharity/extra.less";
@import "less-ecocharity/overlay.less";

// Header
@import "less-ecocharity/header.less";

// Nav
@import "less-ecocharity/nav.less";

// Content Blocks
@import "less-ecocharity/topbar.less";
@import "less-ecocharity/inner-header-title.less";
@import "less-ecocharity/vertical-nav.less";
@import "less-ecocharity/menu-full-page.less";
@import "less-ecocharity/boxed-layout.less";
@import "less-ecocharity/form.less";
@import "less-ecocharity/side-push-panel.less";
@import "less-ecocharity/box-hover-effect.less";
@import "less-ecocharity/gallery-isotope.less";
@import "less-ecocharity/sliders.less";
@import "less-ecocharity/home.less";
@import "less-ecocharity/about.less";
@import "less-ecocharity/campaign.less";
@import "less-ecocharity/volunteer.less";
@import "less-ecocharity/contact.less";
@import "less-ecocharity/event.less";
@import "less-ecocharity/practice-area.less";
@import "less-ecocharity/services.less";
@import "less-ecocharity/job.less";
@import "less-ecocharity/shop.less";
@import "less-ecocharity/blog.less";

// Shortcodes
@import "less-ecocharity/shortcodes.less";


// Widgets
@import "less-ecocharity/widgets.less";


// Footer
@import "less-ecocharity/footer.less";